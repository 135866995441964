import { ref, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';

export default function useVFButtonAuth () {
  const router = useRouter();
  const route = useRoute();
  let urlVF2 = ref('');
  let fromToVF2 = ref('');
  let enableButtonBack = ref(false);

  watch(() => route.query.redirect_data, (redirect_data) => {
    if (redirect_data) {
      const params = new URLSearchParams(atob(redirect_data));
      fromToVF2.value = params.get("client") || '';
      const token = params.get("token");
      if (token) {
        localStorage.setItem("token", token);
      }
      urlVF2.value = params.get("url_referrer") || document.referrer || '';
      localStorage.setItem("FromToVF2", fromToVF2.value);
      localStorage.setItem("urlVF2", urlVF2.value);

      setTimeout(() => {
        const restQuery = { ...route.query };
        delete restQuery.redirect_data;
        router.replace({ query: restQuery });
      }, 10);
    } else {
      fromToVF2.value = localStorage.getItem("FromToVF2") || '';
      urlVF2.value = localStorage.getItem("urlVF2") || '';
    }
    enableButtonBack.value = !!urlVF2.value;
  }, { immediate: true });

  return {
    urlVF2,
    fromToVF2,
    enableButtonBack
  }
}
